import axios from '@/services/axios'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'
import { path } from 'ramda'

export interface LargestTicket {
  summary: string
  status: string
  adaptive_estimation: number
  key: string
  base_url: string
  estimation_type: string
}

export interface Page {
  count: number
  next: string
  previous: string
  results: LargestTicket[]
}

type Context = ActionContext<Page, GlobalState>

const actions = {
  getData(
    { rootGetters }: Context,
    params: { filters: Filters; project_id: number }
  ): Promise<void> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/project-mapping/${params.project_id}/charts/issues/?limit=10&ordering=-adaptive_estimation`,
        { params: params.filters }
      )
      .then(path(['data']))
  },
}

export default {
  namespaced: true,
  actions,
}
