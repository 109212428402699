import { computed, nextTick, ref, watchEffect } from 'vue'
import { useStore } from '@/store'
import { Dictionary, equals, reject } from 'ramda'
import { Filters } from '@/store/modules/filters'
import { chartTypes } from '@/constants/charts/constants'
import { isArray } from 'ramda-adjunct'

export default function useGettingChartData(
  props: {
    projectPage?: boolean
    filters: Filters
  },
  chartName: string
): { response: any; fetchError: any; loading: any; getChartData: any } {
  const store = useStore()
  const response = ref(null)
  const fetchError = ref('')
  const loading = ref(true)

  const useProjectIdInTheEndpoint = computed(
    () =>
      chartName === chartTypes.BudgetPlannedVsUsed ||
      chartName === chartTypes.VelocityFactor ||
      chartName === chartTypes.Activities ||
      chartName === chartTypes.LargestTicketsByEstimation
  )

  const activeFilters = computed(() =>
    reject(equals(null))(props.filters as Dictionary<null>)
  )

  const getChartData = async (
    filters: Filters = activeFilters.value as Filters
  ) => {
    fetchError.value = ''
    loading.value = true
    const projects = props.filters.projects
    const project_id = isArray(projects) ? projects[0] : projects
    try {
      response.value = await store.dispatch(
        `${chartName}/getData`,
        useProjectIdInTheEndpoint.value
          ? {
              filters,
              project_id,
            }
          : filters
      )
      loading.value = false
    } catch (e) {
      if (e instanceof Error) {
        fetchError.value = e?.message
      }
      loading.value = false
    }
  }

  watchEffect(async () => {
    if (props.projectPage && !activeFilters.value.projects) return
    if (
      store.getters['company/selectedCompanyId'] &&
      activeFilters.value.since &&
      activeFilters.value.until
    ) {
      await getChartData()
    }
  })

  return {
    response,
    fetchError,
    loading,
    getChartData,
  }
}
