import axios from '@/services/axios'
import { addFillerData } from '@/utils/chart-utils'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'

export interface EstimationCoveragePerUser {
  coverage: number
  estimated_issues: number
  issue_count: number
  user: {
    name: string
    id: number
  }
  filler?: number
}

export interface EstimationCoverageResponse {
  all_avg_count: number
  avg_count: number
  per_user: EstimationCoveragePerUser[]
}

export interface State {
  data: EstimationCoverageResponse
}

type Context = ActionContext<State, GlobalState>

const actions = {
  getData(
    { rootGetters }: Context,
    params: Filters
  ): Promise<EstimationCoverageResponse> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/charts/estimation-coverage/`,
        { params }
      )
      .then(({ data }) => addFillerData(data, 'coverage'))
  },
}

export default {
  namespaced: true,
  actions,
}
