import axios from '@/services/axios'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'
import { isNotNil } from 'ramda-adjunct'

export interface EstimationAccuracyPerUser {
  overall_factor: number
  overestimation_count: number
  overestimation_factor: number
  overestimation_seconds: null | number
  total_estimated_time: number
  total_spent_time: number
  total_ticket_count: number
  underestimation_count: number
  underestimation_factor: number
  underestimation_seconds: number
  user: { name: string; id: number }
}

export interface EstimationAccuracyDrilldownItem {
  base_url: string
  difference_amount: number
  issue_estimated_time: number
  issue_key: string
  issue_name: string
  issue_total_spent_time: number
}

export interface State {
  data: EstimationAccuracyPerUser[]
}

type Context = ActionContext<State, GlobalState>

const actions = {
  getData(
    { rootGetters }: Context,
    params: Filters
  ): Promise<EstimationAccuracyPerUser> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/charts/estimation-accuracy/`,
        { params }
      )
      .then(
        ({ data }) =>
          data.map((item: EstimationAccuracyPerUser) => ({
            ...item,
            overestimation_factor: isNotNil(item.overestimation_factor)
              ? item.overestimation_factor - 1
              : null,
            underestimation_factor: isNotNil(item.underestimation_factor)
              ? item.underestimation_factor - 1
              : null,
            overall_factor: item.overall_factor - 1,
            total_estimated_time: Math.round(item.total_estimated_time / 3600),
            total_spent_time: Math.round(item.total_spent_time / 3600),
            overestimation_seconds: item.overestimation_seconds
              ? Math.round(item.overestimation_seconds / 3600)
              : 0,
            underestimation_seconds: item.underestimation_seconds
              ? Math.round(item.underestimation_seconds / 3600)
              : 0,
          })) || []
      )
  },
  getEstimationAccuracyDrilldownData(
    { rootGetters }: Context,
    params: Filters
  ): Promise<EstimationAccuracyDrilldownItem[]> {
    return axios
      .get(
        `api/companies/${rootGetters['company/selectedCompanyId']}/charts/estimation-accuracy/drilldown/`,
        { params: params }
      )
      .then(({ data }) => data)
  },
}

export default {
  namespaced: true,
  actions,
}
