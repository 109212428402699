import axios from '@/services/axios'
import { Project } from '@/store/modules/admin/admin'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'

export interface ProjectAllocation {
  allocation_percent: number
  project: Project
}

export interface State {
  data: ProjectAllocation[]
}

type Context = ActionContext<State, GlobalState>

const actions = {
  getData({ rootGetters }: Context, params: Filters): Promise<State> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/charts/project-allocation/`,
        { params }
      )
      .then(({ data }) => data)
  },
}

export default {
  namespaced: true,
  actions,
}
