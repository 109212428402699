import axios from '@/services/axios'
import { addOverFillerData } from '@/utils/chart-utils'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'

export interface WorkloadPerUser {
  user: { name: string; id: number }
  workload: number
  filler?: number
  over_filler?: number
}

export interface WorkloadResponse {
  avg: number
  all_avg: number
  per_user: WorkloadPerUser[]
}

export interface State {
  data: WorkloadResponse
}

type Context = ActionContext<State, GlobalState>

const actions = {
  getData({ rootGetters }: Context, params: Filters): Promise<State> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/charts/workload/`,
        { params }
      )
      .then(({ data }) => addOverFillerData(data, 'workload'))
  },
  getLinkToOpenWorkloadReport(
    { rootGetters }: Context,
    params: { id: number; filters: Filters }
  ): Promise<{ link: string }> {
    const { since, until, projects } = params.filters
    return axios
      .get(
        `/api/companies/${
          rootGetters['company/selectedCompanyId']
        }/charts/workload/report/?original_name=${
          params.id
        }&since=${since}&until=${until}${
          projects ? `&projects=${projects}` : ''
        }  `
      )
      .then(({ data }) => data)
  },
}

export default {
  namespaced: true,
  actions,
}
