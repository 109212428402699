import axios from '@/services/axios'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'
import { LIMIT_OF_COMMITS } from '@/constants/constants'
import { path } from 'ramda'

export interface PerUserActivity {
  commit_count: number | null
  merge_request_count: number | null
  comment_count: number | null
  user: { name: string; id: number }
}

export interface UserActivityResponse {
  all_avg_commit_count: number
  all_avg_merge_request_count: number
  all_avg_comment_count: number
  avg_commit_count: number
  avg_merge_request_count: number
  avg_comment_count: number
  per_user: PerUserActivity[]
}

export interface Page {
  count: number
  next: string
  previous: string
}

export interface MergeRequest {
  created: string
  gitlab_project_name: string
  id: number
  original_project_name: string
  state: string
  title: string
  url: string
}

export interface MergeRequestsPage extends Page {
  results: MergeRequest[]
}

export interface Note {
  created: string
  gitlab_project_name: string
  id: number
  note: string
  original_project_name: string
  url: string
}

export interface NotesPage extends Page {
  results: Note[]
}

export interface Commit {
  committed_date: string
  id: string
  title: string
  web_url: string
  project_name: string
}

export interface CommitsPage extends Page {
  results: Commit[]
}

export interface DataState {
  data: UserActivityResponse
}

type Context = ActionContext<DataState, GlobalState>

const actions = {
  getData({ rootGetters }: Context, params: Filters): Promise<DataState> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/charts/user-activity/`,
        { params }
      )
      .then(({ data }) => data)
  },
  getFirstPageOfCommits(
    { rootGetters }: Context,
    params: Filters
  ): Promise<CommitsPage> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/commits/?limit=${LIMIT_OF_COMMITS}`,
        { params }
      )
      .then(({ data }) => data)
  },
  getFirstPageOfMergeRequests(
    { rootGetters }: Context,
    params: Filters
  ): Promise<MergeRequestsPage> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/merge-requests/?limit=50`,
        { params }
      )
      .then(({ data }) => data)
  },
  getFirstPageOfComments(
    { rootGetters }: Context,
    params: Filters
  ): Promise<NotesPage> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/git/comments/?limit=50`,
        { params }
      )
      .then(({ data }) => data)
  },
  getNextPageOfActivities(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
}

export default {
  namespaced: true,
  actions,
}
