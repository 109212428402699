import { ActionContext } from 'vuex'
import { State as GlobalState } from '../index'

export interface State {
  showSidebar: boolean
  lastVisitedItems: any | null
}

const lastVisitedItems = window.localStorage.getItem('lastVisitedItems')

const state = (): State => ({
  showSidebar: false,
  lastVisitedItems: lastVisitedItems ? JSON.parse(lastVisitedItems) : null,
})

type Context = ActionContext<State, GlobalState>

export const mutations = {
  toggleSidebarValue(state: State): void {
    state.showSidebar = !state.showSidebar
  },
  addLastVisitedItem(
    state: State,
    { item, companyId }: { item: any; companyId: number }
  ): void {
    if (state.lastVisitedItems && state.lastVisitedItems[companyId]) {
      state.lastVisitedItems[companyId] = [
        item,
        ...state.lastVisitedItems[companyId]
          .filter((i: any) => i.href !== item.href)
          .slice(0, 10),
      ]
    } else {
      state.lastVisitedItems = {
        [companyId]: [item],
      }
    }
    window.localStorage.setItem(
      'lastVisitedItems',
      JSON.stringify(state.lastVisitedItems)
    )
  },
}

export const getters = {
  getLastVisitedItemsByCompanyId:
    (state: State) =>
    (id: number): any[] => {
      let items = []
      if (state.lastVisitedItems && state.lastVisitedItems[id]) {
        items = state.lastVisitedItems[id]
      }
      return items
    },
}

const actions = {
  addLastVisitedItem({ rootGetters, commit }: Context, item: any): void {
    const companyId = rootGetters['company/selectedCompanyId']
    if (companyId) {
      commit('addLastVisitedItem', {
        item: {
          name: item.name,
          path: item.path,
          href: item.href,
          query: item.query,
        },
        companyId,
      })
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
