import axios from '@/services/axios'
import { addOverFillerData } from '@/utils/chart-utils'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'

export interface BillableTimePerUser {
  billable_time: number
  user: { name: string; id: number }
  filler?: number
  over_filler?: number
}

export interface BillableTimeResponse {
  all_avg: number
  avg: number
  per_user: BillableTimePerUser[]
}

export interface State {
  data: BillableTimeResponse
}

type Context = ActionContext<State, GlobalState>

const actions = {
  getData(
    { rootGetters }: Context,
    params: Filters
  ): Promise<BillableTimeResponse> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/charts/billable-hours/`,
        { params }
      )
      .then(({ data }) => addOverFillerData(data, 'billable_time'))
  },
}

export default {
  namespaced: true,
  actions,
}
