import axios from '@/services/axios'
import { addFillerData } from '@/utils/chart-utils'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'
import { path } from 'ramda'

export interface ReopenedPerUser {
  count: number
  user: { name: string; id: number }
  filler?: number
}

export interface ReopenedResponse {
  all_avg_count: 0
  avg_count: 0
  per_user: ReopenedPerUser[]
}

export interface UserReopenedTicket {
  absolute_url: string
  base_url: string
  count: number
  issue_id: number
  issue_key: string
  issue_name: string
}

export interface UserReopenedTicketsPage {
  count: number
  previous: string
  next: string
  results: UserReopenedTicket[]
}

export interface UserReopenedTicketDetails {
  changelog_created_at: string
  from_original_status: string
  from_status: string
  id: string
  issue_id: number
  issue_key: string
  to_original_status: string
  to_status: string
}

export interface State {
  data: ReopenedResponse
}

type Context = ActionContext<State, GlobalState>

const actions = {
  getData({ rootGetters }: Context, params: Filters): Promise<State> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/charts/reopened-tickets/`,
        { params }
      )
      .then(({ data }) => addFillerData(data, 'count', false))
  },
  getFirstPageOfUserReopenedTickets(
    { rootGetters }: Context,
    params: {
      since: string
      until: string
      users: number
      projects: string[] | string
    }
  ): Promise<UserReopenedTicketsPage> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/charts/reopened-tickets/drilldown/?limit=50`,
        { params }
      )
      .then(({ data }) => data)
  },
  getNextPageOfReopenedTickets(_: Context, url: string): Promise<void> {
    return axios.get(`${url}`).then(path(['data']))
  },
  getTicketDetails(
    { rootGetters }: Context,
    params: {
      since: string
      until: string
      users: number
      projects: string[] | string
      issue_key: string
    }
  ): Promise<UserReopenedTicketDetails[]> {
    return axios
      .get(
        `/api/companies/${rootGetters['company/selectedCompanyId']}/charts/reopened-tickets/drilldown/detail/?limit=100`,
        { params }
      )
      .then(({ data }) => data.results)
  },
}

export default {
  namespaced: true,
  actions,
}
