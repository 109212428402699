import axios from '@/services/axios'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'

interface MilestonePlanItem {
  date: string
  total_seconds_planned: number
}

interface MilestoneReleaseItem {
  date: string
  description: string
  name: string
  released: boolean
}

export interface ProjectMilestones {
  original_project_id: number
  project_name: string
  plans: MilestonePlanItem[]
  releases: MilestoneReleaseItem[]
}

export interface MilestonesData {
  data: ProjectMilestones[]
}

type Context = ActionContext<MilestonesData, GlobalState>

const actions = {
  getData({ rootGetters }: Context, params: Filters): Promise<MilestonesData> {
    return axios
      .get(
        `api/companies/${rootGetters['company/selectedCompanyId']}/charts/milestones/`,
        { params }
      )
      .then(({ data }) => data)
  },
}

export default {
  namespaced: true,
  actions,
}
