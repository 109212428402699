import axios from '@/services/axios'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'

interface UserActivity {
  date: string
  commits_count: number
  mrs_count: number
  notes_count: number
}

interface UserItem {
  user_id: number
  user_name: string
  data: UserActivity[]
}

export interface ActivitiesData {
  data: UserItem[]
}

type Context = ActionContext<ActivitiesData, GlobalState>

const actions = {
  async getData(
    { rootGetters }: Context,
    params: {
      filters: Filters
      project_id: number
    }
  ): Promise<ActivitiesData> {
    return axios
      .get(
        `api/companies/${rootGetters['company/selectedCompanyId']}/project-mapping/${params.project_id}/charts/bubble/`,
        { params: params.filters }
      )
      .then(({ data }) => data)
  },
}

export default {
  namespaced: true,
  actions,
}
