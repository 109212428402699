import {
  addDays,
  addMonths,
  addSeconds,
  addWeeks,
  differenceInDays,
  format,
  parseISO,
  startOfMonth,
  startOfWeek,
} from 'date-fns'
import { AGGREGATION_TYPE, FILTERS_DATE_FORMAT } from '@/constants/constants'
import { TimeFilters } from '@/store/modules/filters'

export const DATE_FORMATS = {
  DAY_MONTH_YEAR_HOUR_MINUTE: 'dd.MM.yyyy HH:mm',
  YEAR_MONTH_DAY_HOUR_MINUTE: 'yyyy-MM-dd HH:mm',
  HOUR_MINUTE: 'HH:mm',
}

export function calcDateFormat(date: string): string {
  return date
    ? format(parseISO(date), DATE_FORMATS.DAY_MONTH_YEAR_HOUR_MINUTE)
    : ''
}

export function secondsToDays(seconds: number): number {
  return differenceInDays(addSeconds(0, seconds), 0)
}

export const calcAggregationAccordingToTimeFilters = ({
  since,
  until,
}: TimeFilters): string => {
  const daysInMonth = 31
  const daysIn3Months = 93
  const differenceBetweenDays =
    differenceInDays(new Date(until), new Date(since)) + 1
  if (
    differenceBetweenDays >= daysInMonth &&
    differenceBetweenDays <= daysIn3Months
  ) {
    return AGGREGATION_TYPE.WEEK
  } else if (differenceBetweenDays > daysIn3Months) {
    return AGGREGATION_TYPE.MONTH
  } else {
    return AGGREGATION_TYPE.DATE
  }
}

const calcSingleOrPluralWord = (value: number) => {
  return value === 1 ? '' : 's'
}

// we assume that 1 day is 8 hours
// and 1 month is 4 weeks
export const convertSecondsToReadableTime = (seconds: number): string => {
  const sign = Math.sign(seconds)
  const abs = Math.abs(seconds)
  const mon = sign * Math.floor(abs / (3600 * 40 * 4))
  const w = sign * Math.floor(abs / (3600 * 40))
  const d = sign * Math.floor((abs % (3600 * 40)) / (8 * 3600))
  const h = sign * Math.floor((abs % (3600 * 8)) / 3600)
  const m = sign * Math.floor((abs % 3600) / 60)

  if (!w && !d && !h && !m && !mon) return '0'
  return (
    (mon ? `${mon + ' month' + calcSingleOrPluralWord(Math.abs(mon))} ` : '') +
    (w ? `${w + ' week' + calcSingleOrPluralWord(Math.abs(w))} ` : '') +
    (d ? `${d + ' day' + calcSingleOrPluralWord(Math.abs(d))} ` : '') +
    (h ? `${h + ' hour' + calcSingleOrPluralWord(Math.abs(h))} ` : '') +
    (m ? `${m + ' minute' + calcSingleOrPluralWord(Math.abs(m))} ` : '')
  )
}

export const getFirstDayOfWeek = (date: Date) => {
  return format(startOfWeek(date, { weekStartsOn: 1 }), FILTERS_DATE_FORMAT)
}

export const getFirstDayOfMonth = (date: Date) => {
  return format(startOfMonth(date), FILTERS_DATE_FORMAT)
}

export const getFirstDayOfWeekOrMonth = (
  date: Date,
  scaleType: string | undefined
) => {
  if (scaleType === AGGREGATION_TYPE.WEEK) {
    return getFirstDayOfWeek(date)
  } else if (scaleType === AGGREGATION_TYPE.MONTH) {
    return getFirstDayOfMonth(date)
  } else {
    return format(date, FILTERS_DATE_FORMAT)
  }
}

export const getFirstDaysArray = (
  since: string,
  until: string,
  scaleType: string | undefined
) => {
  // Convert the input strings to Date objects
  const sinceDate = getFirstDayOfWeekOrMonth(new Date(since), scaleType)
  const untilDate = getFirstDayOfWeekOrMonth(new Date(until), scaleType)

  // Generate an array containing all the first days between sinceDate and untilDate
  const datesArray = []
  let currentDate = new Date(sinceDate)
  while (currentDate <= new Date(untilDate)) {
    datesArray.push(format(currentDate, FILTERS_DATE_FORMAT))
    if (scaleType === AGGREGATION_TYPE.WEEK) {
      currentDate = addWeeks(currentDate, 1)
    } else if (scaleType === AGGREGATION_TYPE.MONTH) {
      currentDate = addMonths(currentDate, 1)
    } else {
      currentDate = addDays(currentDate, 1)
    }
  }

  return datesArray
}
