import { ActionContext } from 'vuex'
import { State as GlobalState } from '../index'
import { Project, User } from '@/store/modules/admin/admin'

export interface Filters {
  since?: string
  until?: string
  users?: number[] | null
  projects?: number[] | null
  scale_type?: string
}

export interface TimeFilters {
  since: string
  until: string
}

export interface State {
  overviewFilters: Filters
  projectFilters: Filters
}

type Context = ActionContext<State, GlobalState>

const state = (): State => ({
  overviewFilters: {},
  projectFilters: {},
})

const getters = {
  filterItems: (
    state: State,
    _rootGetters: any,
    rootState: GlobalState
  ): { projects: Project[]; users: User[] } => {
    return {
      projects: rootState.admin.original_projects,
      users: rootState.admin.original_users,
    }
  },
}

const mutations = {
  setOverviewFilters(state: State, params: Filters): void {
    state.overviewFilters = params
  },

  setProjectFilters(state: State, params: Filters): void {
    state.projectFilters = params
  },
}

const actions = {
  saveOverviewFilters({ commit }: Context, filters: Filters): void {
    commit('setOverviewFilters', filters)
  },

  saveProjectFilters({ commit }: Context, filters: Filters): void {
    commit('setProjectFilters', filters)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
