import axios from '@/services/axios'
import { Filters } from '@/store/modules/filters'
import { ActionContext } from 'vuex'
import { State as GlobalState } from '../../index'

export interface State {
  velocity_factor: null | number
}

type Context = ActionContext<State, GlobalState>

const actions = {
  getData({ rootGetters }: Context, params: Filters): Promise<number> {
    return axios.get(
      `/api/companies/${rootGetters['company/selectedCompanyId']}/charts/velocity-factor/`,
      { params }
    )
  },
}

export default {
  namespaced: true,
  actions,
}
