import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/Login.vue'
import OverviewDashboard from '../views/OverviewDashboard.vue'
import ProjectDashboard from '../views/ProjectDashboard.vue'
import AdminDashboard from '../views/AdminDashboard.vue'
import Companies from '../views/Companies.vue'
import CompanyCreation from '../views/CompanyCreation.vue'
import { authService } from '@/services/auth'
import { store } from '@/store'
import { isEmpty } from 'ramda'

const selectedCompany = window.localStorage.getItem('selectedCompany')
const selectedCompanyIdFromLocalStorage = selectedCompany
  ? JSON.parse(selectedCompany).id
  : null

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: store.getters['company/selectedCompanyId']
      ? `/company/${store.getters['company/selectedCompanyId']}/overview`
      : selectedCompanyIdFromLocalStorage
      ? `/company/${selectedCompanyIdFromLocalStorage}/overview`
      : '/companies',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/companies',
    name: 'Companies',
    component: Companies,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/company-creation',
    name: 'CompanyCreation',
    component: CompanyCreation,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/company/:id/overview',
    name: 'OverviewDashboard',
    component: OverviewDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/company/:id/projects',
    name: 'ProjectDashboard',
    component: ProjectDashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/company/:id/administration',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: {
      requiresAuth: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth && !(await authService.authenticated())) {
    next('/login')
  } else {
    if (to.params.id) {
      await store.dispatch(
        'company/takeIdFromRouteAndSaveCompanyInLocalStorage',
        +to.params.id
      )
      if (to.name === 'ProjectDashboard') {
        await store.dispatch('filters/saveProjectFilters', to.query)
      } else if (to.name === 'OverviewDashboard') {
        await store.dispatch('filters/saveOverviewFilters', to.query)
      }
    }
    next()
  }
})

router.beforeResolve(async (to) => {
  const shouldAddItem =
    (to.name !== 'ProjectDashboard' && !isEmpty(to.query)) ||
    (to.name === 'ProjectDashboard' &&
      to.query.projects &&
      to.query.projects.length)
  if (shouldAddItem) {
    await store.dispatch('sidebar/addLastVisitedItem', to)
  }
})

export default router
