import { apply, values } from 'ramda'
import { ActiveElement, ChartEvent } from 'chart.js'
import { LineAnnotationOptions } from 'chartjs-plugin-annotation'
import { AGGREGATION_TYPE } from '@/constants/constants'
import { format, getISOWeek, getYear } from 'date-fns'

export const usedColors = {
  'success-400': '#4CD990',
  'key-300': '#97A7F2',
  'warning-300': '#F9DD76',
  'danger-400': '#F18984',
  'info-300': '#61C5E3',
}

export const milestonesColors = {
  tick: '#787878',
  'tooltip-body': '#CFCFCF',
  'point-background': '#000000',
  'point-border': '#F1F1F1',
  'cell-0': '#FFFFFF',
  'cell-1': '#F2F4FE',
  'cell-2': '#DDE3FC',
  'cell-3': '#C2CBF4',
  'cell-4': '#97A7F2',
}

export const randomizedColorsArray = (): string[] => {
  const colors = values(usedColors)
  const colorRandomizerNumber = 16777215
  for (let i = 0; i < 95; i++) {
    let randomColor = Math.floor(
      Math.random() * colorRandomizerNumber
    ).toString(16)
    while (randomColor.length < 6) {
      randomColor = Math.floor(Math.random() * colorRandomizerNumber).toString(
        16
      )
    }
    colors.push(`#${randomColor}`)
  }
  return colors
}

export const roundNumberToThreeSymbols = (value: number): number => {
  return Math.round(value * 1000) / 1000
}

export function addFillerData(data: any, key: string, relative = true): any {
  const dataToParse = data.per_user
  const maxValueFromData = apply(
    Math.max,
    dataToParse.map((item: any) => item[key])
  )
  const maxValueForReopenedTickets =
    maxValueFromData < data.all_avg_count
      ? data.all_avg_count
      : maxValueFromData

  const maxValue = relative ? 100 : maxValueForReopenedTickets
  return {
    ...data,
    per_user: data.per_user.map((user: any) => ({
      ...user,
      filler: maxValue - user[key],
    })),
  }
}

export function addOverFillerData(data: any, key: string): any {
  return {
    ...data,
    per_user: data.per_user.map((user: any) => {
      return 100 < user[key]
        ? {
            ...user,
            [key]: 100,
            over_filler: user[key] - 100,
          }
        : {
            ...user,
            filler: 100 - user[key],
          }
    }),
  }
}

export const showCursorOnLegendHover = (evt: ChartEvent): void => {
  const target = evt.native?.target as HTMLElement
  target.style.cursor = 'pointer'
}

export const hideCursorOnLegendLeave = (evt: ChartEvent): void => {
  const target = evt.native?.target as HTMLElement
  target.style.cursor = ''
}

export const toggleCursorOnChartDataHover = (
  evt: ChartEvent,
  element: ActiveElement
): void => {
  const target = evt.native?.target as HTMLElement
  target.style.cursor = element ? 'pointer' : 'default'
}

export const generateTickForXAxisTimeCharts = (
  value: string,
  scale_type?: string
): string => {
  if (!scale_type || !value) return ''
  if (scale_type === AGGREGATION_TYPE.WEEK) {
    return `${getISOWeek(new Date(value))}`
  } else {
    return format(
      new Date(value),
      scale_type === AGGREGATION_TYPE.DATE ? 'dd, MMM' : 'MMM'
    )
  }
}

export const generateTooltipTitleForXAxisTimeCharts = (
  value: string,
  scale_type?: string
): string => {
  if (scale_type === AGGREGATION_TYPE.WEEK) {
    return `${getISOWeek(new Date(value))} week of ${getYear(new Date(value))}`
  } else {
    return format(
      new Date(value),
      scale_type === AGGREGATION_TYPE.DATE ? 'dd MMM yyyy' : 'MMMM, yyyy'
    )
  }
}

export const verticalAnnotation = (
  color: string,
  label: string,
  value?: any,
  display = true,
  borderDash: number[] = []
): LineAnnotationOptions => {
  return {
    display: display,
    scaleID: 'x',
    value: value || 0,
    borderColor: color,
    borderDash: borderDash,
    borderWidth: 2,
    label: {
      rotation: 'auto',
      enabled: false,
      content: label,
    },
  }
}
